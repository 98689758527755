
.apollo {
    width: 100%;
    height: 50vh;
    background: rgba(22, 22, 22, 0.658);
    position: relative;
  }
  
  .apollo:before {
    content: '';
    position: absolute;
    background: url('../../assets/mh-submenu-bg-min.jpg') no-repeat center center/cover;
    height: 50vh;
    width: 100%;
    z-index: -1;
  }
  


.apollo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.apollo .apollo-content {
    display: block;
    position: absolute;
    top: 40%;
    padding: 1rem;
}



.apollo .apollo-content h1 {
    color: white;
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.apollo h2 {
    color: white;
    font-size: 1.2rem;
    letter-spacing: 7px;
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.apollo h2 {
    font-size: 1rem;
}

.apollo h1 {
    font-size: 2rem;
}

.apollo .apollo-content {
    display: block;
    position: absolute;
    top: 30%;
    padding: 1rem;
}

.apollo .apollo-content h1 {
    font-size: 3rem;
}

}