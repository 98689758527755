@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    z-index: 10;
    background: rgba(255, 255, 255, 0.397);
    position: absolute;
    text-align: center;
    }

.nav-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-box ul {
  margin: auto 0;
}

.navbar-logo {
  z-index: 9999999;
  width: 250px;
  padding: .5rem;
}

.navbar-logo img {
  width: 100%;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #d48e0ce0;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}


.show {
  display: none;
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: right;
    display: flex;
    padding: .8rem 0;
    
}
.navbar li {
    width: max-content;
    padding: .3rem 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.navbar li a {
  color: #000000;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}


.navbar li a:hover {
  color: rgb(23, 183, 231);
}

  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
    font-family: 'Roboto', sans-serif;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(41, 40, 40) !important;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


.nav-main-flex {
  background-color: rgb(255, 255, 255);
  padding: 1px;
  border-radius: 10px;
}

.sub-flex {
  display: flex;
  width: 350px;
  background-color: rgb(97, 177, 214);
  border: 1px solid rgba(255, 255, 255, 0.486);
  border-radius: 5px;
  padding: 1rem;
  margin: .5rem;
}

.sub-flex-right {
  width: 400px;
  margin: auto;
  text-align: center;
}

.sub-flex-right li {
  width: 250px;
}

.sub-flex-right p {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.sub-flex-right li a {
  color: rgb(255, 255, 255);
}


.sub-flex-left {
  width: 100px;
}

.sub-flex-left img {
  width: 100%;
}


/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: left;
  max-height: 80vh;
  overflow-y: auto;
}




/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #ffffff;
    border: 1px solid #ffffff00;
    border-radius: 10px;
    /* width: 735px; */
    height: auto;
    left: -10rem;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }


  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 7px;
  }







@media screen and (max-width: 1200px) {


      .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .show {
      display: block;
    }

    .hide {
      display: none;
    }
    
    .navbar li a {
      color: rgb(27, 26, 26);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(238, 240, 241);
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1rem;
      }

      .sub__menus {
        width: 250px;
      }

      .nav-main-flex {
        display: block;
      }


      .sub__menus {
        width: 370px;
        left: -8rem;

      }

}



@media screen and (max-width: 700px) {
/* Modal content */
.modal-content {
  width: 95%;
}


}


