.tms-section2 {
    padding: 3rem 0;
}

.tms-section2-flex {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 1rem;
}

.tms-section2-left {
    width: 50%;
    padding: 1rem;
}

.tms-section2-left h1 {
    padding: 10px 0;
    text-align: center;
}

.tms-section2-left p {
    text-align: justify;
}

.tms-section2-right {
    width: 600px;
    padding: 1rem;
    margin: auto;
}

.tms-section2-right img {
    width: 100%;
    border-radius: 1rem;
}


@media screen and (max-width:1200px) {

    .tms-section2-flex {
        flex-wrap: wrap-reverse;
    }

    .tms-section2-left {
        width: 100%;
    }

    .tms-section2-right {
        width: 80%;
    }
}


@media screen and (max-width:700px) {
    .tms-section2-right {
        width: 100%;
    }
}