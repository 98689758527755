@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contactform {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 1rem;
}

.contact2-header h1, .contact2-header p {
  font-family: 'Montserrat', sans-serif;
}

.contact-container {
  width: 100%;
}

.contactFormRight {
  width: 100%;
  margin: auto;
}

.contactFormRight img{
  width: 100%;
}



.contact-form-content {
  background-color: #ffffffd0;
  border-radius: .5rem;
  width: 100%;
  -webkit-box-shadow: 0px 0px 65px -53px rgba(66,65,66,1);
  -moz-box-shadow: 0px 0px 65px -53px rgba(66,65,66,1);
  box-shadow: 0px 0px 65px -53px rgba(66,65,66,1);
  padding: 2rem;
}


.form-hide h1 {
  color: rgb(41, 39, 39);
  font-weight: normal;
}

.form-hide h2 {
  font-weight: normal;
  color: rgb(41, 39, 39);
  font-size: 1rem;
}

.topic-text h1 {
  font-size: 20px;
}

.topic-text p {
  text-align: left;
 }

.contact-form-content .right-side {
  font-size: 14px;
  font-weight: 400;
  color: rgb(41, 39, 39);
  font-family: 'Montserrat', sans-serif;
  text-align: start;
}

.radio-button p {
  text-align: left;
  font-size: 13px;
  padding-left: .2rem;
  color: rgb(41, 39, 39);
}

.input .radio-ans {
  text-align: left;
}


.right-side .input-box {
  height: 40px;
  width: 100%;
  margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: rgb(41, 39, 39);
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(7, 157, 202, 0.199);
}

.right-side .message-box{
  min-height: 110px;
}

.right-side .input-box textarea {
  padding-top: 6px;
}

.right-side .button{
  display: inline-block;
  margin-top: 12px;
}

.right-side .contact-btn {
  color: rgb(255, 255, 255);
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.right-side .contact-btn:hover {
  background: rgb(182, 114, 50);
}


.disclaimer p {
  padding: 1rem 0;
  font-size: 12px;
  color: rgb(41, 39, 39);
}

.disclaimer span {
  padding: 1rem 0;
  font-size: 12px;
  color: rgb(29, 103, 201);
}

.dob p {
  color: rgb(19, 18, 18);
  font-size: 13px;
  margin-bottom: 0;
  text-align: left;
}

.dob label {
  padding: 0;
}

.radioBtn {
  display: flex;
}


@media (max-width: 940px) {

  .form-hide {
    display: block;
  }

  .contactform {
    flex-wrap: wrap;
  }

  .contact2-container {
    padding: 1rem ;
  }
  .contact2-container .content .right-side {
   width: 75%;
   margin-left: 55px;
}
  .contact2-container .contact-form-content{
    flex-direction: column-reverse;
    margin: 0rem 0;
  }

 .contact2-container .contact-form-content .right-side{
   width: 100%;
   margin-left: 0;
 }

 .contact2-container .contact-form-content .left-side{
  width: 100%;
}

.contactFormBtn input{
  width: 100%;
}


}


@media (max-width: 700px) {
  .contact-card {
    width: 80%;
  }


}


