.gad7-bg {
  width: 100%;
  height: auto;
  position: relative;
  }
  
  .gad7-bg:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg2.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

label {
    display: block;
    margin: 5px 0;
}

input[type="radio"] {
    margin-right: 10px;
    accent-color: #584d4d;
}

  .gad7 {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 1rem;
  }

  .gad7-header {
    padding: 2rem 0;
  }

  .gad7-header h1 {
    color: rgb(39, 37, 37);
    font-size: 1.5rem;
  }

  .gad-question {
    padding: 1rem 0;
  }

  .gad7-options {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    color: rgb(27, 26, 26);
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid rgba(255, 255, 255, 0.158);
  }


  .gad7-content p {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: left;
    color: var(--primary-color);
    font-family: 'roboto';
    letter-spacing: 1px;
  }

  .gad7-image {
    width: 100%;
    margin: auto;
  }

  .gad7-image img {
    width: 100%;
  }

  .gad7-container {
    width: 100%;
    height: auto;
    background-color: #10aac51c;
    padding: 1rem;
  }

  .gad7-result h2 {
    color: rgb(46, 43, 43);
    font-size: 1.3rem;
    font-weight: normal;
    padding: 1rem 0;
  }

  .gad7-input input  {
    padding: .6rem 2rem;
    margin-bottom: 1rem;
    border: 1px solid var(--primary-color);
    width: 100%;
  }

  .gad7-container button {
    background-color: var(--primary-color);
    color: white;
    width: 100%;
  }

  .gad7-container button:hover {
    background-color: rgb(86, 193, 235);
    color: white;
    width: 100%;
  }

  .result-note p {
    font-size: 14px;
    font-style: italic;
  }

  .result-disclaimer p {
    font-size: 14px;
    font-style: italic;
  }

/* TABLE */

table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px auto;
}

th, td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}




  @media screen and (max-width:940px) {
    .gad7 {
      flex-wrap: wrap;
    }

    .gad7-image {
      width: 90%;
      margin: auto;
    }

    .gad7-image h1 {
      text-align: center;
    }

    .gad7-container {
      width: 100%;
      background-color: #10aac51c;
      padding: 1rem;
    }

    .result-disclaimer p {
      font-size: 12px;
    }

  }

  @media screen and (max-width:600px) {
    .gad7 {
      flex-wrap: wrap;
    }

    .gad7-image {
      width: 100%;
      margin: auto;
    }
  }