@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.about {
    width: 100%;
    height: 60dvh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #ffffff00;
}
  
  .about:before {
    content: '';
    position: absolute;
    background: url('../../assets/about_hero.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.about .about-content {
    display: block;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
    background: linear-gradient(rgba(255, 255, 255, 0.555), rgba(255, 255, 255, 0.452));    
}

.about h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: #df8c10;
    text-align: center;

}

.about p {
    color: #df8c10;
    text-align: center;
}





/* MEDIA QUERIES */

@media screen and (max-width:700px) {


    
.about .about-content {
    right: 0%;
    padding: 1rem;
    border-radius: 1rem;
}

.about h1 {
    font-size: 3rem;
}
}