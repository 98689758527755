@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.nasalEsketamine {
    width: 100%;
    height: 100dvh;
    background: rgba(21, 3, 27, 0);
    position: relative;
  }
  
  .nasalEsketamine:before {
    content: '';
    position: absolute;
    background: url('../../assets/esketamine-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.nasalEsketamine {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.nasalEsketamine .nasalEsketamine-content {
    display: block;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
    background: linear-gradient(rgba(255, 255, 255, 0.527), rgba(255, 255, 255, 0.452));    
}


.nasalEsketamine h1 {
    color: rgb(22, 24, 24);
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}


/* MEDIA QUERIES */


@media screen and (max-width:940px) {
    
    .mh-iop h1 {
        font-size: 2rem;
    }

    
    .nasalEsketamine h1 {
        font-size: 2rem;
    }
    
    }