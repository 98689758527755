@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
.quizzes {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  
  .quizzes:before {
    content: '';
    position: absolute;
    /* background: url('../../assets/quiz-bg.jpg') no-repeat center top/cover; */
    height: 100%;
    width: 100%;
    z-index: -7;
  }
  


.quizzes-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 8rem 1rem 1rem 1rem;
}

.quizzes-content-right {
    width: 400px;
    text-align: center;
    margin: auto;
}

.quizzes-content-right img {
    width: 100%;
    height: auto;
}

.quizzes-content-left {
    width: 50%;
    margin: auto;
}

.quizzes-content-left p {
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    color: rgb(14, 13, 13);
    font-size: 16px;
    padding: 1rem 0;
    text-align: center;
}

.quizzes-content-left h1 {
    font-size: 2rem;
    text-transform: uppercase;
    color: rgb(20, 20, 20);
    text-align: center;
}




@media screen and (max-width:940px) {
    .quizzes-content-right {
        width: 70%;
    }

    .quizzes-content-left {
        width: 90%;
    }

    .quizzes-content {
        flex-wrap: wrap-reverse;
    }

    .quizzes-content-left h1 {
        text-align: center;
        font-size: 1.5rem;
    }

    .quizzes-content-left p {
        font-size: 12px;
    }
}

@media screen and (max-width:700px)  {
    .quizzes-content-right {
        width: 90%;
    }

}

