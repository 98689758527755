@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 60dvh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(255, 255, 255, 0);
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/call_us.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    display: block;
    position: absolute;
    bottom: 0%;
    right: 10%;
    padding: 1rem;
    width: 40%;
}

.contact-hero h1 {
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(48, 46, 46);
}

.contact-hero p {
    font-family: 'Montserrat', sans-serif;
    color: rgb(48, 46, 46);
    line-height: normal;
}

.contact-hero h2 {
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(48, 46, 46);
    padding: 1rem 0;

}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.contact-hero h2 {
    font-size: 1.5rem;
    text-align: center;
}

.contact-hero h1 {
    font-size: 2rem;
    text-align: center;
}

.contact-hero p {
    text-align: center;
    font-size: 12px;
}

.contact-hero .contact-hero-content {
    left: 0%;
    width: 100%;   
    background-color: rgba(255, 255, 255, 0.596);

}


}