@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
.section2 {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(29, 28, 29, 0);

  }
  
  .section2:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg3.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -7;
    opacity: .08;
  }

.section2-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 5rem 1rem;
}

.section2-content-right {
    width: 100%;
    text-align: center;
    margin: auto;
}

.section2-content-right img {
    width: 100%;
    height: auto;
}

.section2-content-left {
    width: 100%;
    margin: auto;
}

.section2-content-left p {
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-text);
    font-size: 15px;
    line-height: normal;
}

.section2-content-left p span {
    color: #4b98ac;
}

.section2-content-left h1 {
    font-size: 1.5rem;
    padding: 1rem 0;
    text-transform: uppercase;
}

.section2-btn {
    display: flex;
    gap: 1rem;
}

.section2-btn button {
    background-color: rgb(153, 210, 233);
    color: white;
    font-size: 14px;
}

.hide {
    display: none;
}

.show {
    display: block;
}


@media screen and (max-width:940px) {


    .hide {
        display: block;
    }

    .show {
        display: none;
    }
    
    .section2-content-right {
        width: 100%;
    }

    .section2-content-left {
        width: 100%;
        padding-top: 2rem;
    }

    .section2-content {
        flex-wrap: wrap-reverse;
    }

    .section2-content-left h1 {
        text-align: center;
    }
}

@media screen and (max-width:600px) {
    .section2-btn {
        flex-wrap: wrap;
        width: 100%;
        gap: 0;

    }

    .section2-btn .section2-btn1 {
        width: 100%;
        padding: 0 auto;
    }

    .section2-btn .section2-btn2 {
        width: 100%;
    }

    .section2-btn1 button {
        width: 100%;
    }

    .section2-btn2 button {
        width: 100%;
    }
}

