@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.InsuranceSection2 {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(29, 28, 29, 0);
    padding: 3rem 0;
    margin: 2rem 0;

  }
  
  .InsuranceSection2:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .06;
  }


.InsuranceSection2-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 2rem 0;
}

.InsuranceSection2-container {
    text-align: center;
    padding: 2rem 0;
}

.InsuranceSection2-container p {
        padding: 1rem;
        font-family: 'Montserrat', sans-serif;
    }

.InsuranceSection2 h1 {
    font-size: 3.5rem;
    color: var(--primary-color);
    font-family: 'Instrument Serif', serif;
    letter-spacing: 5px;
}

.InsuranceSection2 h2 {
    text-transform: uppercase;
    color: var(--primary-dark);
    font-size: 1rem;
    letter-spacing: 5px; 
}

.InsuranceSection2-thumbnail  {
    width: 200px;
}




@media  screen and (max-width:940px) {
    .InsuranceSection2-container h1 {
        font-size: 1.5rem;
        padding: 1rem 0;
    }


    .InsuranceSection2-container p {
        font-size: 14px;
    }


}






