@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');

.insuranceLogos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.card-name {
    color: black;
    font-weight: bold;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 1rem;
}

.card-description {
    color: black;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 1rem;
    font-size: smaller;
}


.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.insurance-props {
    width: 100px;
}

.insurance-props img {
    width: 100%;
    height: auto;
}

.card-button {
    border-radius: 10px;
    margin-left: 27%;
}

@media screen and (max-width:940px) {
    .about .content {
    width: 100%;
    }

    .image {
        width: 60%;
    }

    .insuranceLogos {
        margin-top: 10rem;
        text-align: center;
        width: 100%;
    }
}

