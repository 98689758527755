@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');

@font-face {
  font-family: "Hallenger";
  src: local("Hallenger"),
       url("../../components/fonts/Hallenger.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Gorgeous";
  src: local("Gorgeous"),
       url("../../components/fonts/Gorgeous.ttf") format("truetype");
  font-weight: bold;
}

.hero {
  width: 100%;
  height: auto;
  position: relative;
  background: rgba(0, 0, 0, 0);
}

.hero:before {
  content: '';
  position: absolute;
  background: url('../../assets/blur-medical-and-clinic-of-hospital.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}


.heroContent {
  display: flex;
  justify-content: center;
  width: 100%;
}



.heroImage {
  width: 100%;
  height: 100dvh;
  position: relative;
  background: rgba(0, 0, 0, 0);
}

.heroImage:before {
  content: '';
  position: absolute;
  background: url('../../assets/tms_technician.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}


.contentCaption {
  width: 100%;
  margin: auto;
  padding: 1rem;
}

.hero h1 {
    font-size: 5rem;
    line-height: 4.5rem;
    color: #141414;
    letter-spacing: 1px;
    font-family: 'Roboto Slab', serif;
    font-family: "Hallenger";
    text-align: center;

}

.hero h2 {
    font-size: 2rem;
    color: rgb(6, 109, 128);
    padding-bottom: 1rem;
    font-family: "Hallenger";
    text-align: center;

}

.imageText {
  position: absolute;
  bottom: 10%;
  display: none;
  padding: 1rem;
}




/* MEDIA QUERIES */

@media screen and (max-width:940px) {


  
  .imageText {
    width: 100%;
    display: block;
    background-color: rgba(255, 255, 255, 0.438);
  }

  .contentCaption {
    display: none;
  }

  .heroContent {
    flex-wrap: wrap;
  }


}
