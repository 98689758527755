



.access-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.access-flex-item {
    text-align: center;
}

.access-flex-item h1 {
    font-size: 2.5rem;
}

.access-flex-item h2 {
    font-size: 1rem;
    font-weight: 300;
}

@media (max-width: 700px) {
    .access-flex {
        flex-wrap: wrap;
    }
}