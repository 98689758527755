
.phq9-bg {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(255, 255, 255, 0);
  }
  
  .phq9-bg:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg2.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

.phq9 {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 1rem;
  }

.phq9-image {
    width: 100%;
    margin: 0 auto;
  }

  .phq9-image img {
    width: 100%;
  }

  .phq9-container {
    width: 100%;
    height: auto;
    background-color: #10aac51c;
    padding: 1rem;
  }

  .phq9-question p {
    text-align: left;
  }

  .phq9-options {
    border-bottom: 1px solid rgba(0, 0, 0, 0.993);
    padding-bottom: 1rem;
  }

  .phq9-options {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    color: rgb(27, 26, 26);
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid rgba(26, 24, 24, 0.158);
  }

  .phq9-result h2 {
    color: rgb(46, 43, 43);
    font-size: 1.3rem;
    font-weight: normal;
    padding: 1rem 0;
  }

  .phq9-input input  {
    padding: .6rem 2rem;
    margin-bottom: 1rem;
    border: 1px solid var(--primary-color);
    width: 100%;
  }

  .phq9-container button {
    background-color: var(--primary-color);
    color: white;
    width: 100%;
  }

  .phq9-container button:hover {
    background-color: rgb(86, 193, 235);
    color: white;
    width: 100%;
  }

  .phq9Result p{
    text-align: left;
  }

  @media screen and (max-width:940px) {
    .phq9 {
      flex-wrap: wrap;
    }

    .phq9-image {
      width: 90%;
      margin: auto;
    }

    .phq9-image h1 {
        text-align: center;
      }

    .phq9-container {
      width: 100%;
      background-color: #10aac51c;
      padding: 1rem;
    }

  }

  @media screen and (max-width:600px) {

    .phq9-image h1 {
        font-size: 1.2rem;
        text-align: center;
    }

    .phq9 {
      flex-wrap: wrap;
    }

    .phq9-image {
      width: 100%;
      margin: auto;
    }
  }