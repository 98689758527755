@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.insuranceSection3 {
    padding: 5rem 0 5rem 0;
    background-color: rgb(153, 210, 233);
}

.insuranceSection3-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.insuranceSection3-content-left {
    width: 100%;
    margin: auto;
}

.insuranceSection3-content-left img  {
    width: 100%;
    display: block;
}

.insuranceSection3-content-right {
    width:100%;
    margin: auto;
}


.insuranceSection3-content-right h2 {
    font-size: 2rem;
    color: #ffffff;
}

.insuranceSection3-content-right h1 {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 1rem;
    letter-spacing: 8px; 
}

.insuranceSection3-content-right p {
    line-height: 25px;
    text-align: justify;
    font-size: 15px;
    color: rgb(37, 35, 35);
}

.insurance-section3-btn {
    text-align: left;
    padding: 2rem 0;
}

.insurance-section3-btn button {
    background-color: rgb(255, 255, 255);
    font-size: 15px;
    text-transform: uppercase;
}



@media screen and (max-width:940px) {
    .insuranceSection3-content {
        flex-wrap: wrap;
        gap: 2rem;
    }

    .insuranceSection3-content-right h2 {
        font-size: 1.5rem;
        color: #ffffff;
    }
    

}

