



.testimonials {
    width: 100%;
    padding: 5rem 0;
  }

  .testimonials h3 {
    margin-bottom: 20px;
  }
  .testimonials .card {
    border-bottom: 3px #007bff solid !important;
    transition: 0.5s;
    margin-top: 60px;
  }
  .testimonials .card i {
    background-color: #007bff;
    color: #ffffff;
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: -40px auto 0 auto;
  }
  
  .row p {
    width: 100%;
    padding: 0;
  }

  .quote p {
    font-size: 3rem;
    color: rgb(153, 210, 233);
  }

  .five-stars {
    color: gold;
    font-size: 1rem;
    margin-top: -1.5rem;
  }

