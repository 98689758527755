@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.FAQ {
    width: 100%;
    height: 100vh;
    background: #ebebeb42;
    position: relative;
  }
  
  .FAQ:before {
    content: '';
    position: absolute;
    background: url('../../assets/faq.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.FAQ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.FAQ .FAQ-content {
    display: block;
    position: absolute;
    top: 50%;
    padding: 1rem;
    background: linear-gradient(rgba(255, 255, 255, 0.137), rgba(255, 255, 255, 0.185));    

}

.FAQ h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(240, 170, 19);
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.FAQ h1 {
    font-size: 2rem;
    text-align: center;
}

}