.topbar {
    width: 100%;
    height: 30px;
    padding: .4rem;
    background-color: #d48e0ce0;
}

.topbar .topbar-content {
    display: flex;
    justify-content: space-around;
    color: rgb(238, 241, 243);
    padding: 0;
}

.topbar .topbar-content p {
    padding: 0 1rem;
    font-size: 13px;
    text-align: center;
    color: rgb(236, 240, 241);
    text-align: left;
}

.topbar .bar-box1 {
    width: 25rem;
    border-right: 1px solid rgba(255, 255, 255, 0.459);
}

.topbar .bar-box2 {
    width: 23rem;
    border-right: 1px solid rgba(255, 255, 255, 0.459);
}

.address a{
    color: rgb(236, 240, 241);
}

.topbar span {
    color: rgb(255, 255, 255);
}
@media screen and (max-width: 940px) {
    .top-hide {
        display: none;
    }

    .topbar .bar-box2 {
        width: 12rem;
    }

}